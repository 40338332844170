

@font-face {
  font-family: "font-1";
  /* src: url(./assets/Montserrat-Regular.ttf); */
  src: url(../assets/Montserrat-Regular.ttf);
}

* {
  list-style-type: none;
}
body {
  background: #c1002a;
  color: white;
  text-align: center;
  font-family: "font-1", cursive;
}

h1 {
  font-size: 2rem;
  letter-spacing: 2px;
  margin: 40px 10px 40px;
}
.h1_accueil{
margin-left: -10px;
margin-top: -0px;
font-size: 20px;
}
.input {
  border: none;
  border-radius: 25px 25px 25px 25px;
  outline: none;
  text-align: center;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: "font-1", cursive;
  margin: 3px 0px;
  width: 280px;
  padding: 8px 0px;
}

input[type="submit"] {
  border-radius: 25px 25px 25px 25px;
  background: rgb(177, 176, 176);
  transition: 0.3s ease;
  cursor: pointer;
}
input[type="submit"]:hover {
  background: rgb(46, 46, 46);
  color: white;
  letter-spacing: 1px;
}

img {
  object-fit: cover;
  width: 40%;
  max-width: 250px;
}

img.small {
  object-fit: cover;
  width: 40%;
  max-width: 64px;
}
.ligne_cours{
  border-bottom:solid black 3px ;
  margin-bottom: 10px;
}

.result-container {
  margin: 0 auto;
}

ul {
  display: flex;
  justify-content: center;
  column-count: 2;
  flex-wrap: wrap;
  padding: 0;
  margin: auto;
  max-width: 1400px;
}
/* li {
  max-width: 400px;
  border: 2px solid rgb(206, 206, 206);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
} */
h2 {
  margin: 10px 0 25px;
}

.row {
  /* vertical-align: center; */
  margin-left: -10px;
  margin-top: 20px;
}

.column {
  float: left;
  padding: 10px;
  vertical-align: center;
}

.left, .right {
  width: 25%;
}

.middle {
  width: 50%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

a {
  outline: none;
  text-decoration: none;
  color: white;
  padding: 2px 1px 0;
}

a:link {
  color: white;
}
.li_infos{
  max-width: 400px;
  border: 2px solid rgb(206, 206, 206);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}
.img_power{
width: 40px;
margin-top: -10px;
}
.logopower{
width:100.8px ;
height: 100.8px;
}
.messageRetourAccueil{
font-size: 20px;
}
.imgerreur{
border-radius:15%;
filter: drop-shadow(30px 10px 4px black);
}
.loading{
/* visibility: hidden; */
/* background: linear-gradient( grey, grey);
z-index: 5;
margin-top:-480px ;
margin-left: 300px; */
}
