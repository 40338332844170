.tableau_cours{
    border: solid black 3px;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 250px;
    height: 450px;
    font-size: 18px;
    box-shadow: 5px 5px 5px black;
  }
  .ligne_fin_tab{
    margin-bottom: 15px;
  }
  .ligne_titre{
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .ligne_tableau{
    margin-bottom: 15px;
  }
  .body_cours{
    display: flex;
    justify-content: center;
  }
  .img_cours{
    margin-top: 20px;
    filter: drop-shadow(30px 10px 4px black);
  }
  .img_profil{
    width:150px; 
    height:150px;
    margin-bottom:'15px';
  }