.signatureCanvas {
  border: 1px solid black;
  width: 80%;
  height: 80%;
  background-color: white;
  justify-content: flex-start;
  }
  .signatureCanvasM {
    border: 1px solid black;
    width: 80%;
    height: 80%;
    background-color: white;
    justify-content: flex-start;
    }
.pad_signature{
    flex-wrap: wrap;
}
