.IMG_Metier_Recto{
    background-image: url("../assets/images/Carte_Metier/Carte_Metier_Recto.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 400px;
    height: 250px;
    max-width:450px;
    max-height:300px;
    border: solid 2px black;
    position: relative;
    text-align: center;
  }
  @media (max-width:400px){
    .IMG_Metier_Recto{
        width: 100%;
    }
  }
  .IMG_Metier_Verso{
    background-image: url("../assets/images/Carte_Metier/Carte_Metier_Verso.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    width: 400px;
    height: 250px;
    max-width:450px;
    max-height:300px;
    border: solid 2px black;
    position: relative;
    text-align: center;
    /* background-attachment: local; */
  }
  @media (max-width:400px){
    .IMG_Metier_Verso{
        width: 100%;
    }
  }
  .img_profil_auditeur_recto{
    position: absolute;
    width: 80px;
    height: 80px;
    transform: translate(-10%, 0%);
    margin-top: 110px;
    margin-left: 95px;
  }
  .input_nom_auditeur_recto{
    position: absolute;
    top:88px;
    left: 75px;
    transform: translate(-4%, 0%);
    height:15px;
    width: 165px;
    border: none;
  }
  .input_prenom_auditeur_recto{
    position: absolute;
    top:110px;
    left: 85px;
    height:15px ;
    width: 155px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .input_naissance_auditeur_recto{
    position: absolute;
    top:135px;
    left: 86px;
    height:15px ;
    width: 155px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .input_validite_auditeur_recto{
    position: absolute;
    top:160px;
    left: 85px;
    height:15px ;
    width: 170px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .div_signature_auditeur{
    position: absolute;
    background-repeat: no-repeat;
    top: 180px;
    width: 250px;
    height: 45px;
    z-index: 1;
    /* border: 5px solid black; */
  }
  .img_signature_auditeur{
    position: absolute;
    margin-left: -30px;
    /* margin-top: 15px; */
    max-width: 100px;
  }
  .img_btn-modif_sign_auditeur{
    width: 850px;
    height: 40px; 
    border: solid black 0.5px;
    margin-left: 50px;
  }
  .contenant_auditeur {
    position: relative;
    text-align: center;
    cursor: pointer;
    width: 250px;
    height: 40px;
    /* border: solid black 1px; */
  }
  .texte_centrer_auditeur {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 5px;
    width: 250px;
    height: 40px;
    margin-left: 50px;
  }
  .input_modif_auditeur {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
  .span_modif_auditeur{
      cursor: pointer;
      border: solid white 2px;
      padding: 5px 5px 5px 8px;
      display: flex;
      width: 200px;
      margin-left: 67px;
      margin-top: -30px;
      margin-bottom: 10px;
  }
  .input_organisme_verso{
    z-index:4;
    position: absolute;
    top:140px;
    left: 105px;
    height:15px ;
    width: 155px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .input_adresse_verso{
    z-index:0;
    position: absolute;
    top:160px;
    left: 106px;
    height:15px ;
    width: 155px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .input_adresse_verso_CP{
    z-index:0;
    position: absolute;
    top:175px;
    left: 106px;
    height:15px ;
    width: 155px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .input_tel_verso{
    z-index:0;
    position: absolute;
    top:198px;
    left: 105px;
    height:15px ;
    width: 155px;
    transform: translate(-6%, 0%);
    border: none;
  }
  .input_chef_verso{
    position: absolute;
    top:80px;
    left: 255px;
    height:15px ;
    width: 85px;
    transform: translate(-6%, 0%);
    border: none;
    z-index:0;
  }
  .span_dl{
    cursor: pointer;
    color: white;
    font-size: 13px;
    background: black;
    padding: 5px 5px 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 9%;
    width: 80px;
  }
  .input_modif {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
  .msg_auditeur{
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  }
  .elem_profil{
    /* border: solid black 1px;  */
    width: 10px;
    height: 10px;
  }
  .img_cnam{
    position:absolute;
    z-index: 5;
    margin-top: 160px;
    margin-left: 30px;
    width: 130px;
    /* border: solid 5px black; */
  }
