.ul_profil{
    width: 300px;
    height: 400px;
  }
  .img_pp{
  /* border-radius: 5%; */
  width: 120px;
  padding-left: 10px;
  padding-top: -0px;
  margin-top: -30px;
  padding-bottom: 5px;
  }
  .input_profil{
  flex-direction: column;
  width: 150px;
  }
  .bloc_profil{


  }
  .profil{
  margin-bottom:8px;
  text-align: center;
  border-radius: 25px 25px 25px 25px;
  outline: none;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  font-family: "font-1", cursive;
  margin: 3px 0px;
  width: 300px;
  padding: 10px 0px;
  }
  .texte_area_adresse{
    width: 270px;
    height: 50px;
    margin-bottom:8px;
    text-align: center;
    border-radius: 25px 25px 25px 25px;
    outline: none;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    font-family: "font-1", cursive;
    margin: 3px 0px;
    padding: 10px 0px;
  }
  .texte_area_formation{
    width: 270px;
    height: 80px;
    margin-bottom:8px;
    text-align: center;
    border-radius: 25px 25px 25px 25px;
    outline: none;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    font-family: "font-1", cursive;
    margin: 3px 0px;
    padding: 10px 0px;
  }
  .profil_formation{
    margin-bottom:8px;
    text-align: center;
    border-radius: 25px 25px 25px 25px;
    outline: none;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    font-family: "font-1", cursive;
    margin: 3px 0px;
    width: 350px;
    padding: 10px 0px;
    margin-top: 10px;
  }
  .adresse{
  height: 30px;
  }
  .label{
  display: flex;
  margin-bottom: 5px;

  }
  .label_Formation{
    margin-top: 40px;
  }
  .div_formation{
    align-items: center;
    margin-top: 15px;
  }
  .h1_profil{
margin-top: 15px;
margin-left: 10px;
font-size: 20px;
  }
  .div_entete{
    display: flex;
    flex-direction: row;
    width: 400px;
  }
  .small_profil{
    /* flex-direction: row; */
    width: 40px;
    height: 40px;
    max-width: 64px;
  }
  .img_CNAM{
    margin-top: 220px;
    margin-left: 20px;
  }