.input_civilite{
margin: .4rem; 
width: 1.5em; 
height: 1.5em;
}
.div_radio{
flex-direction: row;
}
.label_radio{
margin-right: 15px;
}