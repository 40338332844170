.IMG_Auditeur_Recto{
  background-image: url("../assets/images/Carte_Auditeur/CarteAuditeur_Recto.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 400px;
  height: 250px;
  max-width:450px;
  max-height:300px;
  border: solid 2px black;
  position: relative;
  text-align: center;
}
@media (max-width:400px){
  .IMG_Auditeur_Recto{
      width: 100%;
}
}
.IMG_Auditeur_Verso{
  background-image: url("../assets/images/Carte_Auditeur/CarteAuditeur_Verso.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 400px;
  height: 250px;
  max-width:450px;
  max-height:300px;
  border: solid 2px black;
  position: relative;
  text-align: center;
  /* background-attachment: local; */
}
@media (max-width:400px){
  .IMG_Auditeur_Verso{
      width: 100%;
  }
}
.msg{
  display: flex;
  flex-direction: column;
  margin-top: 5px;

}
.span_modif{
  cursor: pointer;
  border: solid white 2px;
  padding: 5px 5px 5px 8px;
  display: flex;
  width: 200px;
  margin-left: 67px;
  margin-top: -30px;
  margin-bottom: 10px;
}
.span_dl{
  cursor: pointer;
  color: white;
  font-size: 13px;
  background: black;
  padding: 5px 5px 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 9%;
  width: 80px;
}
.input_modif {
  width: 0px;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
.img_signature{
  margin-left: 15px;
  margin-top: 0px;
  max-width: 130px;
}
/* .div_signature{
  width: 580px;
} */
.img_btn-modif_sign{
  width: 850px;
  height: 40px; 
  border: solid white 1px;
  margin-left: 50px;
}

.contenant{
  position: relative;
  text-align: center;
  cursor: pointer;
  width: 250px;
  height: 40px;
  /* border: solid black 1px; */
}
.texte_centrer {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 5px;
  width: 250px;
  height: 40px;
  margin-left: 45px;
}
.div_metier{
  border: solid 2px black;
  border-radius: 8px;
  display: flex;
  max-width: 100%;
}
.div_titre_input{
padding-left: 5px;
padding-right: 10px;
display: flex;
flex-wrap: wrap;
flex-direction: column;
justify-content: center;
width: 250px;
}
.titre_carte{
  font-size: 20px;
}
.div_cadre_logo{
  width: 140px;
}
.btn_metier{
  box-shadow: 5px 5px 5px black;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  height: 55px;
  padding-bottom: 10px;
  font-family: "font-1";
}
.div_signature{
  /* border: 5px solid black; */
  position: absolute;
  background-repeat: no-repeat;
  top: 180px;
  width: 250px;
  height: 45px;
  z-index: 1;
}
.img_profil{
  position: absolute;
  width: 80px;
  height: 80px;
  transform: translate(2%, 0%);
  margin-top: 105px;
  margin-left: 55px;
}
@media (max-width:90px){
  .img_profil{
      width: 100%;
  }
}
.input_nom{
  position: absolute;
  top:79px;
  left: 59px;
  transform: translate(-4%, 0%);
  height:15px;
  width: 145px;
  border: none;
}
.input_prenom{
  position: absolute;
  top:104px;
  left: 75px;
  height:15px ;
  width: 135px;
  transform: translate(-6%, 0%);
  border: none;
}
.input_naissance{
  position: absolute;
  top:125px;
  left: 76px;
  height:15px ;
  width: 135px;
  transform: translate(-6%, 0%);
  border: none;
}
.input_validite{
  position: absolute;
  top:150px;
  left: 76px;
  height:15px ;
  width: 170px;
  transform: translate(-6%, 0%);
  border: none;
}
.image-upload>input {
  display: none;
  cursor: pointer;
}
.div_carte_metier{
justify-content: center;
}
.div_carte{
  display: flex;
  justify-content: center;
}
.inputAuditeur_organisme_verso{
  z-index:4;
  position: absolute;
  top:100px;
  left: 90px;
  height:15px ;
  width: 155px;
  transform: translate(-6%, 0%);
  border: none;
}
.inputAuditeur_adresse_verso{
  z-index:0;
  position: absolute;
  top:122px;
  left: 90px;
  height:15px ;
  width: 195px;
  transform: translate(-6%, 0%);
  border: none;
}
.inputAuditeur_adresse_verso_CP{
  z-index:0;
  position: absolute;
  top:145px;
  left: 90px;
  height:15px ;
  width: 195px;
  transform: translate(-6%, 0%);
  border: none;
}
.inputAuditeur_tel_verso{
  z-index:0;
  position: absolute;
  top:190px;
  left: 90px;
  height:15px ;
  width: 155px;
  transform: translate(-6%, 0%);
  border: none;
}
.inputAuditeur_chef_verso{
  position: absolute;
  top:77px;
  left: 205px;
  height:15px;
  width: 85px;
  transform: translate(-6%, 0%);
  border: none;
  z-index:0;
}
    