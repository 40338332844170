.picto-item {
    position: relative;  /*les .picto-item deviennent référents*/
    cursor: help;
    border-radius: 50%;
  }
  
  /* on génère un élément :after lors du survol et du focus :*/
  
  .picto-item:hover:after,
  .picto-item:focus:after {
    content: attr(aria-label);  /* on affiche aria-label */
    position: absolute;
    top: -2.4em;
    left: -130px;
    transform: translateX(-50%); /* on centre horizontalement  */
    z-index: 1; /* pour s'afficher au dessus des éléments en position relative */
    white-space: nowrap;  /* on interdit le retour à la ligne*/
    padding: 5px 10px;
    background: black;
    color: #fff;
    border-radius: 4px;
    font-size: 0.90rem;
  }
  
  /* on génère un second élément en :before pour la flèche */
  
  [aria-label]:hover:before,
  [aria-label]:focus:before {
    content: "▼";
    position: absolute;
    top: -1em;
      left: 50%;
      transform: translateX(-50%); /* on centre horizontalement  */
    font-size: 20px;
    color: #413219;
  }
  
  /* pas de contour durant le :focus */
  [aria-label]:focus {
    outline: none;
  }
  
  p, .pictos {
    text-align: center;
  }
  
  .picto-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 .2em;
    width: 0.80em;
    height: 0.80em;
    color: #413219;
    background: white;
    font-size: 1.80rem;
    text-align: center;
    text-decoration: none;
  }