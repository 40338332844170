.bloc_campus{
    border: solid 2px black;
    border-radius: 5%;
    display: flex;
    justify-content: center;
  }
  .cadre_img_campus{
    border: solid 2px black;
    border-radius: 5%;
  }
  .img_campus{
    width: 100%;
  }